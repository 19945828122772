// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-photos-page-js": () => import("./../src/templates/photos-page.js" /* webpackChunkName: "component---src-templates-photos-page-js" */),
  "component---src-templates-registry-page-js": () => import("./../src/templates/registry-page.js" /* webpackChunkName: "component---src-templates-registry-page-js" */),
  "component---src-templates-travel-page-js": () => import("./../src/templates/travel-page.js" /* webpackChunkName: "component---src-templates-travel-page-js" */)
}

